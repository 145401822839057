<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <div class="sidebar" v-if="sidebar.length > 0">
        <div v-for="(item, index) in sidebar" :key="index" class="sidebarItem" @click="newsDetails(item)">
          <div class="title"> {{ item.title }} </div>
        </div>
      </div>
      <div class="cont">
        <div class="title">{{ detail.title }}</div>
        <div class="titleTwo">{{ detail.title2 }}</div>
        <div class="clickNum">阅读量：{{ detail.click_num }}</div>
        <div v-if="videoList.length > 0" class="videoList">
          <video class="video" :poster="item.videoimage" controls @ended="endVideo(index)" @play="handlePlay(index)"
            style="width:800px;height: 400px" v-for="(item, index) in videoList" :key="index" v-if="item.location == 1">
            <source :src="item.video" type="video/mp4">
            <source :src="item.video" type="video/ogg">
            <source :src="item.video" type="video/webm">
          </video>
        </div>
        <div v-html="detail.content"></div>
        <div v-if="videoList.length > 0" class="videoList">
          <video class="video" :poster="item.videoimage" controls @ended="endVideo(index)" @play="handlePlay(index)"
            style="width:800px;height: 400px" v-for="(item, index) in videoList" :key="index" v-if="item.location == 2">
            <source :src="item.video" type="video/mp4">
            <source :src="item.video" type="video/ogg">
            <source :src="item.video" type="video/webm">
          </video>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：新闻中心-新闻中心详情
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-18 16:31
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  inject: ["reload"], // 刷新页面
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "新闻中心",
          title_en: "News Center",
          path: "newsCenter",
        },
        {
          title_zh: "新闻中心",
          title_en: "News Center",
          path: "",
        },
      ],
      sidebar: [],
      detail: "", // 详情
      title: "", // 标题
      id: "", // 新闻id

      videoList: [], // 视频列表
      videoElement: []
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.title = this.$route.query.title;
    this.getNewsInfo();
    this.videoElement = document.getElementsByTagName('video') // 获取页面上所有的video对象
  },
  methods: {

    handlePlay(index) {
      const videoElement = this.videoElement
      if (videoElement && videoElement.length > 0) {
        for (let i = 0; i < videoElement.length; i++) {
          if (i === index) {
            this.videoElement[i].play()
          } else {
            this.videoElement[i].pause()
          }
        }
      }
    },

    /**
     * 新闻详情
     * 刘嘉鑫
     * 2022-8-18
     */
    getNewsInfo() {
      this.$request({
        url: "/Index/newsInfo",
        data: {
          id: this.id, // 新闻id
        },
      }).then((res) => {
        console.log("新闻详情", res);
        res.news_info.content = res.news_info.content.replace(
          new RegExp("<img", "g"),
          "<img style='max-width:100%;height:auto;'"
        );
        this.detail = res.news_info;
        this.sidebar = res.news_list;
        this.videoList = res.news_info.videoconfig

      });
    },

    /**
     * 侧边栏新闻详情
     * 刘嘉鑫
     * 2022-8-18
     */
    newsDetails(item) {
      this.$router.push({
        path: "newsCenterDetails",
        query: { id: item.id, title: item.title },
      });
      this.reload();
    },
  },
};
</script>
<style lang='scss' scoped>
:deep(img) {
  display: initial !important;
}

.outer {
  background: #f6f6f6;
  padding: 78px 310px 120px;
  display: flex;
  align-items: flex-start;
}

.sidebar {
  width: 14%;
  background: #ffffff;
  padding: 30px 20px 0;
  margin-right: 41px;

  .sidebarItem {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #eeeeee;
    margin-bottom: 30px;
    cursor: pointer;

    .title {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .sidebarItem:last-child {
    border: none !important;
    margin: 0 0 10px 0 !important;
  }
}

.cont {
  background: #ffffff;
  padding: 58px;
  flex: 1;

  .title {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #222222;
    margin-bottom: 20px;
  }

  .titleTwo {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    margin-bottom: 20px;
  }


  .clickNum {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    margin-bottom: 60px;
  }
}

.videoList {
  text-align: center;
}
</style>